import { USER_EVENTS, UserEvent } from "@src/constants";
import { Shift, ShiftStages } from "@src/lib/interface";

export const getStageText = (stage: ShiftStages): string => {
  switch (stage) {
    case ShiftStages.CLOCK_IN:
      return "Clock In";
    case ShiftStages.LUNCH_IN:
      return "End Break";
    case ShiftStages.LUNCH_OUT:
      return "Start Break";
    case ShiftStages.SKIP_LUNCH:
      return "Skip Break";
    case ShiftStages.CLOCK_OUT:
      return "Clock Out";
    case ShiftStages.SHIFT_TIME_DONE:
      return "Upload Your Timesheet";
    case ShiftStages.GET_FACILITY_SIGNATURE:
      return "Get Workplace Signature";
    case ShiftStages.GET_SIGNATURE:
      return "Get Signature";
    default:
      return "";
  }
};

const getStageDescription = (stage: ShiftStages) => {
  switch (stage) {
    case ShiftStages.CLOCK_IN:
      return "clock in";
    case ShiftStages.LUNCH_IN:
      return "end your break";
    case ShiftStages.LUNCH_OUT:
      return "start your break";
    case ShiftStages.CLOCK_OUT:
      return "clock out";
    case ShiftStages.SHIFT_TIME_DONE:
      return "upload your timesheet";
    case ShiftStages.GET_FACILITY_SIGNATURE:
      return "get the workplace signature";
    case ShiftStages.GET_SIGNATURE:
      return "get the signature";
    default:
      return "";
  }
};

export const getStageObject = (params: {
  shift: Shift;
  isSignatureSubmission?: boolean;
  isCaliforniaTimesheetEnabled?: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
}): {
  currentStage: ShiftStages;
  currentStageText: string;
  currentStageDescription: string;
  currentStageLog: UserEvent | undefined;
} => {
  const { shift, isSolveUnpaidBreaksEnabled, isCaliforniaTimesheetEnabled, isSignatureSubmission } =
    params;
  const { verified = false, clockInOut = null, lunchInOut = null } = shift;
  if (verified || (clockInOut && clockInOut.end)) {
    if (isSignatureSubmission && isCaliforniaTimesheetEnabled) {
      return {
        currentStage: ShiftStages.SHIFT_TIME_DONE,
        currentStageText: getStageText(ShiftStages.GET_SIGNATURE),
        currentStageDescription: getStageDescription(ShiftStages.GET_SIGNATURE),
        currentStageLog: undefined,
      };
    }
    if (isSignatureSubmission) {
      return {
        currentStage: ShiftStages.SHIFT_TIME_DONE,
        currentStageText: getStageText(ShiftStages.GET_FACILITY_SIGNATURE),
        currentStageDescription: getStageDescription(ShiftStages.GET_FACILITY_SIGNATURE),
        currentStageLog: undefined,
      };
    }
    return {
      currentStage: ShiftStages.SHIFT_TIME_DONE,
      currentStageText: getStageText(ShiftStages.SHIFT_TIME_DONE),
      currentStageDescription: getStageDescription(ShiftStages.SHIFT_TIME_DONE),
      currentStageLog: undefined,
    };
  }

  if (!clockInOut || !clockInOut.start) {
    return {
      currentStage: ShiftStages.CLOCK_IN,
      currentStageText: getStageText(ShiftStages.CLOCK_IN),
      currentStageDescription: getStageDescription(ShiftStages.CLOCK_IN),
      currentStageLog: USER_EVENTS.CLOCK_IN,
    };
  }

  /*
    Removing break actions when isSolveUnpaidBreaksEnabled is true.
    At the end of this effort we will completely remove break actions from the app.
    Adding breaks will be done in timecard upload step.
   */
  if (isSolveUnpaidBreaksEnabled) {
    return {
      currentStage: ShiftStages.CLOCK_OUT,
      currentStageText: getStageText(ShiftStages.CLOCK_OUT),
      currentStageDescription: getStageDescription(ShiftStages.CLOCK_OUT),
      currentStageLog: USER_EVENTS.CLOCK_OUT,
    };
  }

  if (lunchInOut && (lunchInOut.end || lunchInOut.isSkipped)) {
    return {
      currentStage: ShiftStages.CLOCK_OUT,
      currentStageText: getStageText(ShiftStages.CLOCK_OUT),
      currentStageDescription: getStageDescription(ShiftStages.CLOCK_OUT),
      currentStageLog: USER_EVENTS.CLOCK_OUT,
    };
  }

  if (lunchInOut && lunchInOut.start) {
    return {
      currentStage: ShiftStages.LUNCH_IN,
      currentStageText: getStageText(ShiftStages.LUNCH_IN),
      currentStageDescription: getStageDescription(ShiftStages.LUNCH_IN),
      currentStageLog: USER_EVENTS.END_BREAK,
    };
  }

  return {
    currentStage: ShiftStages.LUNCH_OUT,
    currentStageText: getStageText(ShiftStages.LUNCH_OUT),
    currentStageDescription: getStageDescription(ShiftStages.LUNCH_OUT),
    currentStageLog: USER_EVENTS.START_BREAK,
  };
};
